<template>
    <div class="soft-wrap">
        <div class="no-data" v-if="!articleList.id" @click="createArticle">
            <div class="data-content">
                <i class="el-icon-plus"></i>
                <div class="show-title">新的创作</div>
            </div>
        </div>
        <div class="soft-item" v-else>
            <div class="item-content">
                <div class="top-content">
                    <img :src="articleList.cover" alt="">
                    <div class="hover-box">
                        <div class="hover-item edit" @click="editArticle(articleList.id)">编辑</div>
                        <div class="hover-item delete" @click="delArticle(articleList.id)">删除</div>
                    </div>
                </div>
                <div class="bottom-content" @click="goToDetail">
                    <div class="bottom-title">{{articleList.title}}</div>
                    <div class="bottom-text" v-html="articleList.content"></div>
                    <div class="bottom-info">
                        <div class="info-left">更新于：{{articleList.create_time}}</div>
                        <div class="info-right">
                            <img class="weibo" v-if="articleList.platform === 1"
                                 src="../../../../../assets/images/student/weibo.png">
                            <img class="wx" v-if="articleList.platform === 2"
                                 src="../../../../../assets/images/student/weixin.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {StuOpSocialList, StuOpSocialDel} from '@/utils/apis'

    export default {
        name: "SoftText",
        data() {
            return {
                //软文列表
                articleList: {
                    id: null,
                    title: '',
                    cover: '',
                    content: '',
                    create_time: '',
                    type: null,
                    platform: null,
                },
                opId: Number(this.$route.query.op_id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
                //社交电商类型
                social_type: Number(this.$route.query.social_type) || null,
            }
        },
        created() {
            this.getArticleList();
        },
        methods: {
            //获取软文列表
            getArticleList() {
                let param = {
                    op_id: this.opId,
                    type: this.social_type
                }
                if (this.courseId) {
                    param.course_id = this.courseId;
                }
                StuOpSocialList(param).then(res => {
                    var timestamp1 = res.data.create_time;//时间戳
                    var timestamp2 = new Date(parseInt(timestamp1) * 1000);
                    res.data.create_time = timestamp2.toLocaleDateString().replace(/\//g, "-") + " " + timestamp2.toTimeString().substr(0, 8);
                    this.articleList = res.data;
                }).catch(err => {
                    console.error(err);
                })
            },
            createArticle() {
                if (this.courseId) {
                    this.$router.push({
                        path: '/student/trainCenter/internetMarketing/socialECommerce/softText/create',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            social_type: this.social_type,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/internetMarketing/socialECommerce/softText/create',
                        query: {
                            id: this.opId,
                            social_type: this.social_type
                        }
                    })
                }
            },
            editArticle(id) {
                if (this.courseId) {
                    this.$router.push({
                        path: '/student/trainCenter/internetMarketing/socialECommerce/softText/create',
                        query: {
                            id: this.opId,
                            edit_id: this.articleList.id,
                            social_type: this.social_type,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/internetMarketing/socialECommerce/softText/create',
                        query: {
                            id: this.opId,
                            edit_id: this.articleList.id,
                            social_type: this.social_type
                        }
                    })
                }
            },
            delArticle(id) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    StuOpSocialDel(id).then((res) => {
                        this.getArticleList()
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            goToDetail() {
                if (this.courseId) {
                    this.$router.push({
                        path: '/student/trainCenter/internetMarketing/socialECommerce/softText/detail',
                        query: {
                            id: this.opId,
                            article_id: this.articleList.id,
                            social_type: this.social_type,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/internetMarketing/socialECommerce/softText/detail',
                        query: {
                            id: this.opId,
                            article_id: this.articleList.id,
                            social_type: this.social_type
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .soft-wrap {
        margin-top: 18px;
        height: calc(100% - 18px);
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .soft-item {
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            width: 385px;

            .item-content {
                padding: 5px 6px 18px;

                .top-content {
                    height: 160px;
                    position: relative;

                    &:hover {
                        .hover-box {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .hover-box {
                        position: absolute;
                        display: flex;
                        top: 6px;
                        right: 10px;
                        opacity: 0;

                        .hover-item {
                            width: 40px;
                            height: 40px;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            opacity: 0.85;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            &.edit {
                                color: #2461EF;
                            }

                            &.delete {
                                color: #FF0000;
                                margin-left: 18px;
                            }
                        }
                    }
                }

                .bottom-content {
                    cursor: pointer;
                    margin-top: 12px;

                    .bottom-title {
                        font-size: 18px;
                        color: #333333;
                    }

                    .bottom-text {
                        color: #999999;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        ::v-deep p {
                            img {
                                display: none;
                            }
                        }
                    }

                    .bottom-info {
                        color: #666666;
                        display: flex;
                        justify-content: space-between;

                        .info-right {
                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

            }
        }

        .no-data {
            width: 478px;
            height: 203px;
            background: #E7F6EF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            position: relative;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .data-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 30px;
                    color: #2DC079;
                }

                .show-title {
                    margin-top: 20px;
                    font-size: 18px;
                    color: #666666;
                }
            }
        }
    }
</style>